import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar';
import MobileSidebar from '../MobileSidebar';
import { db } from '../../firebase';
import moment from 'moment';
import {  collection, onSnapshot, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
//MUI
import { useMediaQuery, Card, CardContent,  Button, CardHeader, } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';



const columns = [
    { field: 'name', headerName: 'Navn', width: 220 },
    { field: 'phone', headerName: 'Telefonnummer', width: 220 },
    { field: 'date', headerName: 'Dato', width: 200 },
     {
        field: 'Button',
        headerName: 'Slett',
        width: 100,
        renderCell: RenderButtonCell,
    },


];

function RenderButtonCell(params) {
    const id  = params.row.id;
    const handleDelete =  async() => {
        try {
            await deleteDoc(doc(db, "comp", id));
        } catch (error) {
            console.log("Det var en feil ved sletting av dokument ", error, " Prøv igjen eller kontakt Systemansvarlig")
        }
    };
    return (
        <Button onClick={handleDelete} color="error">Slett</Button>
    );
}



const Comp = () => {
    const isPhone = useMediaQuery('(max-width:990px)');
    const [data, setData] = useState([])


    useEffect(() => {
        const ref = collection(db, "comp");
        const q = query(ref, orderBy("date", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => { // Pass `q` here
            const data = snapshot.docs.map((doc) => {
                let date = doc.data().date
                    ? moment(doc.data().date.toDate()).format('DD/MM/YYYY HH:mm:ss')
                    : moment().format('DD/MM/YYYY HH:mm:ss');
                return { id: doc.id, ...doc.data(), date };
            });
            setData(data);
        });
        return () => {
            unsubscribe();
        };
    }, []);
    

    console.log(data)

    return (
        <div className='dashboard-main'>
            {isPhone ? (
                <MobileSidebar /> // Show this component on phones
            ) : (
                <Sidebar className="sidebar-menu" />
            )}
            <div className='dashboard-main-content'>

                <Card sx={{mt:5, mb:10}}>
                    <CardHeader
                        title="Konkuranse"
                        subheader="Se påmeldinger"
                    />
                    <CardContent>
                        <DataGrid
                            rows={data}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 15,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            disableRowSelectionOnClick
                        />
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default Comp


